import { useState, useEffect } from "react";
import { XMarkIcon, ChevronLeftIcon, ChevronRightIcon, ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";

export default function Popup() {
    const [popupEnabled, setPopupEnabled] = useState(false);
    const [currentPosition, setCurrentPosition] = useState(0);
    const [carouselImages, setCarouselImages] = useState([])
    const BASE_HOST = "https://mpscnk.aestree.com"

    const popup__modal = function () {

        setTimeout(function () {
            const popUp = document.querySelector(".popup");
            popUp.classList.remove("popup__hidden");

            document.addEventListener("click", function () {
                popUp.classList.add("popup__hidden");
            });

            setTimeout(function () {
                popUp.classList.add("popup__hidden");
            }, 30000);
        }, 2000);
    }

    useEffect(() => {
        fetch(`${BASE_HOST}/api/website?popup`)
            .then(res => res.json())
            .then(data => {
                if (data.popup && data.popup.status == 1) {
                    const timer = window.setTimeout(() => {
                        fetch(`${BASE_HOST}/api/website/popup_images`)
                            .then(res => res.json())
                            .then(data => {
                                setCarouselImages(data)
                            })
                        setPopupEnabled(true);
                    }, 2000);

                }
            })

        if (popupEnabled) {
            popup__modal();
        }
    }, [])

    function switchCarousel(type) {
        if (type == 'prev') {
            setCurrentPosition(prevState => currentPosition != 0 ? prevState -= 1 : (carouselImages.length - 1))
        } else if (type == 'next') {
            setCurrentPosition(prevState => currentPosition != (carouselImages.length - 1) ? prevState += 1 : 0)
        }

        console.log(currentPosition);
    }

    function dismissPopupCarousel() {
        document.querySelector(".popup__carousel").classList.add("popup__carousel__hidden")
    }


    return (
      popupEnabled && carouselImages.length > 0 ?
        <div className="popup__carousel">
            <button className="popup__carousel__dismiss" onClick={dismissPopupCarousel}><XMarkIcon /></button>
            {
                carouselImages[currentPosition].link ?
                <a className="popup__carousel__img--link" href={carouselImages[currentPosition].link} title="Click to open link!" target="_blank"><ArrowTopRightOnSquareIcon /><img src={`${BASE_HOST}/api/website/popup_image_file/${carouselImages[currentPosition].id}`} className="popup__carousel__img" /></a> :
                <img src={`${BASE_HOST}/api/website/popup_image_file/${carouselImages[currentPosition].id}`} className="popup__carousel__img" />
            }
            {
                carouselImages.length > 1 ?
                    <>
                    <div className="popup__carousel__buttons">
                        <button className="popup__carousel__prev" onClick={() => switchCarousel("prev")}><ChevronLeftIcon /></button>
                        <button className="popup__carousel__next" onClick={() => switchCarousel("next")}><ChevronRightIcon /></button>
                    </div>
                    <div className="popup__carousel__dots">
                        {
                            carouselImages.map((img, index) => <span className={`${currentPosition == carouselImages.indexOf(img) ? 'active' : ''}`} onClick={() => setCurrentPosition(index)}></span>)
                        }
                    </div>
                    </>
                    : null
            }
        </div> : null

    )
}